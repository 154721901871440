import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DropzoneArea } from 'react-mui-dropzone';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  FormControlLabel,
  Switch,
  Button,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import ContractIcon from '../../assets/images/contract_icon.png';
import { useAuthUpdateContext } from '../../auth/AuthUpdateProvider';
import {
  CreateModelFromFile,
  CreateModelFromInputs,
} from '../../redux/actions/modelActions';
import { setErrMsg, setSuccessMsg } from '../../redux/actions/snackbarActions';
import { MODEL_EDIT_ROUTE } from '../../routes.config';
import ModalsStyles from '../../styles/Modals';
import PlaybookBuilderStyles from '../../styles/PlaybookBuilderStyles';
import CombineStyles from '../../utils/CombineStyles';
import { handleDropZoneMsg, handlePreviewIcon } from '../../utils/FileUpload';
import { UpdateModelAttributes } from '../../utils/requests';

const COMMENT_LENGTH_LIMIT = 50;

const modelStageOptions = [
  {
    key: 'draft',
    label: 'Draft',
  },
  {
    key: 'published',
    label: 'Published',
  },
  {
    key: 'backup',
    label: 'Backup',
  },
];

function CreateEditModel({
  classes,
  open,
  handleClose,
  model = null,
  trainingModels = [],
  setModel = null,
  groups,
  questionnaires,
}) {
  const {
    getAuthHeader,
    config: { playbookBuilderFeatures, contractTypes, showNewPlaybookBuilder },
  } = useAuthUpdateContext();
  const edit = !!model;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [groupLookup, setGroupLookup] = useState({});
  const [modelLookup, setModelLookup] = useState({});
  const [file, setFile] = useState(null);

  const setSuccess = (value) => dispatch(setSuccessMsg(value));
  const setErr = (value) => dispatch(setErrMsg(value));

  const { register, handleSubmit, watch, getValues, setValue } = useForm({
    defaultValues: {
      title: edit ? model.name : '',
      comment: edit ? model.comment : '',
      contractType: edit ? model.contractType : '',
      isDeleteProtected: edit ? model.isDeleteProtected : false,
      stage: edit ? model.stage : 'draft',
      defaultQuestionnaire: edit ? model.defaultQuestionnaire : null,
      groups: [],
      enableBullseyeByEmail: edit ? model.enableBullseyeByEmail : false,
      bullseyeTrainingModels: edit ? model.bullseyeTrainingModels : [],
      generateAddendum: edit ? model.generateAddendum : false,
      addendumTemplate: edit
        ? model.addendumTemplate
        : 'templates/addendum_template.docx',
      emailOnUpload: edit ? model.emailOnUpload : false,
      convertPdfToDocx: edit ? model.convertPdfToDocx : false,
      nano_slot_model: edit ? model.nano_slot_model : '{}',
      enableRiskRouting: edit ? model.enableRiskRouting : false,
      enableNewFsdMethod: edit ? model.enableNewFsdMethod : false,
    },
  });
  const watchAll = watch();
  const [formValid, setFormValid] = useState(false);
  useEffect(() => {
    if (
      watchAll.title.length > 0 &&
      watchAll.contractType &&
      watchAll.groups.length > 0 &&
      watchAll.comment?.length < COMMENT_LENGTH_LIMIT
    )
      setFormValid(true);
    else if (
      watchAll.title.length > 0 &&
      watchAll.groups.length > 0 &&
      watchAll.comment?.length < COMMENT_LENGTH_LIMIT &&
      file
    )
      setFormValid(true);
    else setFormValid(false);
  }, [watchAll]);
  const setDefaults = () => {
    setGroupLookup(
      groups.reduce((acc, { _id: id, name }) => {
        acc[id] = name;
        return acc;
      }, {})
    );
    setModelLookup(
      trainingModels.reduce((acc, { _id: id, name }) => {
        acc[id] = name;
        return acc;
      }, {})
    );
    if (edit) {
      setValue('title', model.name);
      setValue('comment', model.comment);
      setValue('contractType', model.contractType);
      setValue('isDeleteProtected', model.isDeleteProtected);
      setValue('stage', model.stage);
      setValue('defaultQuestionnaire', model.defaultQuestionnaire || null);
      setValue('enableBullseyeByEmail', model.enableBullseyeByEmail || false);
      setValue('bullseyeTrainingModels', model.bullseyeTrainingModels || []);
      setValue('generateAddendum', model.generateAddendum);
      setValue('emailOnUpload', model.emailOnUpload || false);
      setValue('convertPdfToDocx', model.convertPdfToDocx || false);
      setValue('addendumTemplate', model.addendumTemplate);
      setValue('nano_slot_model', model.nano_slot_model || '{}');
      setValue('enableRiskRouting', model.enableRiskRouting || false);
      setValue('enableNewFsdMethod', model.enableNewFsdMethod || false);
      const validGroups = groups.reduce((acc, { _id: id, models }) => {
        const hasModel =
          models.filter(({ _id: modelId }) => modelId === model._id).length > 0;
        if (hasModel) {
          acc.push(id);
        }
        return acc;
      }, []);
      setValue('groups', validGroups);
    } else {
      setValue('title', '');
      setValue('comment', '');
      setValue('contractType', '');
      setValue('isDeleteProtected', false);
      setValue('stage', 'draft');
      setValue('defaultQuestionnaire', null);
      setValue('groups', []);
      setValue('enableBullseyeByEmail', false);
      setValue('enableRiskRouting', false);
      setValue('bullseyeTrainingModels', []);
      setValue('generateAddendum', false);
      setValue('emailOnUpload', false);
      setValue('convertPdfToDocx', false);
      setValue('addendumTemplate', 'templates/addendum_template.docx');
      setValue('nano_slot_model', '{}');
      setValue('enableNewFsdMethod', false);
    }
  };

  useEffect(() => {
    setDefaults();
  }, [model, groups]);

  let saveBtnText = 'Create Playbook';
  let titleText = 'Create new playbook';
  if (edit) {
    titleText = 'Edit Playbook';
    saveBtnText = 'Update Playbook';
  }

  const getFilteredQuestionnaires = () => {
    if (watchAll.contractType) {
      return questionnaires.filter(({ type }) => type === watchAll.contractType);
    }
    return [];
  };

  const onSubmit = (data) => {
    data.name = data.title; // downstream code all assumes there's a 'name' field so set here rather than do lots of renames
    if (edit) {
      // TODO: make the UpdateModel thunk to fill in here
      const updatedModel = {
        ...model,
        _id: model._id,
        name: data.title,
        comment: data.comment,
        isDeleteProtected: data.isDeleteProtected,
        stage: data.stage,
        defaultQuestionnaire: data.defaultQuestionnaire,
        groups: data.groups,
        enableBullseyeByEmail: data.enableBullseyeByEmail,
        bullseyeTrainingModels: data.bullseyeTrainingModels,
        generateAddendum: data.generateAddendum,
        emailOnUpload: data.emailOnUpload,
        convertPdfToDocx: data.convertPdfToDocx,
        addendumTemplate: data.addendumTemplate,
        nano_slot_model: data.nano_slot_model,
        enableRiskRouting: data.enableRiskRouting,
        enableNewFsdMethod: data.enableNewFsdMethod,
      };
      if (setModel) {
        setModel(updatedModel);
      }
      return getAuthHeader()
        .then((headers) => UpdateModelAttributes(headers, updatedModel))
        .then(() => handleClose())
        .catch((err) => {
          console.error(err);
          setErr('Failed to create model');
        });
    }
    if (file) {
      // create from file
      return getAuthHeader().then((headers) => {
        Promise.resolve(dispatch(CreateModelFromFile(headers, file, data)))
          .then((modelId) => {
            handleClose();
            navigate(`${MODEL_EDIT_ROUTE}/${modelId}`, { modelId });
          })
          .catch((err) => {
            console.error('Error! Failed to create model from file with error:', err);
          });
      });
    } else {
      // create from input
      return getAuthHeader()
        .then((headers) =>
          Promise.resolve(dispatch(CreateModelFromInputs(headers, data)))
        )
        .then((modelId) => {
          handleClose();
          navigate(`${MODEL_EDIT_ROUTE}/${modelId}`, { modelId });
        })
        .catch((err) => {
          console.log('Error! Failed to create model with error:', err);
          setErr('Failed to create model');
        });
    }
  };

  const handleCancel = () => {
    setDefaults();
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-label="Edit or Create a playbook"
      className={`${classes.modalWrapper}`}
    >
      <div style={{ overflow: 'hidden', overflowY: 'visible' }}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={classes.modalContentWrapper}
          style={{ overflowY: 'scroll' }}
        >
          <div className={`container ${classes.modalHeader} pb-2 pt-4`}>
            <div className="row pb-4 text-center">
              <div className="col pt-3 pl-5 pr-0">
                <img src={ContractIcon} alt="" className={`${classes.modelImg} pb-4`} />
                <Typography variant="h2" id="deleteUser-label">
                  {titleText}
                </Typography>
                <Typography variant="body2" className="pt-2">
                  A playbook controls the types of edits you make for a specific contract
                  type.
                </Typography>
              </div>
              <div className="col-auto d-flex flex-column justify-content-start">
                <IconButton
                  color="inherit"
                  aria-label="Close model modal"
                  onClick={handleClose}
                  edge="end"
                  autoFocus
                  size="large"
                >
                  <CloseIcon className={classes.modalCloseIcon} />
                </IconButton>
              </div>
            </div>
          </div>
          <div
            className="container pl-5 pr-5 pt-5 pb-5"
            style={{ backgroundColor: 'white' }}
          >
            {edit || !playbookBuilderFeatures.createFromFile ? null : (
              <div className="row pt-3">
                <div className="col">
                  <DropzoneArea
                    dropzoneText="Upload an existing playbook file here"
                    onChange={(files) => {
                      if (files.length > 0) {
                        setValue('title', files[0].name.replace('.json', ''));
                        setFile(files[0]);
                      } else {
                        setFile(null);
                      }
                    }}
                    getPreviewIcon={handlePreviewIcon}
                    showFileNames
                    onAlert={(msg, variant) =>
                      handleDropZoneMsg(msg, variant, setErr, setSuccess)
                    }
                    showAlerts={false}
                    clearOnUnmount
                    maxFileSize={300000000}
                    filesLimit={1}
                    acceptedFiles={['.json']}
                  />
                </div>
              </div>
            )}
            <div className="row pt-3">
              <div className="col">
                <InputLabel id="title-label" className={classes.labelText}>
                  Name
                </InputLabel>
                <TextField
                  variant="outlined"
                  fullWidth
                  {...register('title')}
                  inputProps={{
                    'aria-labelledby': 'title-area-label',
                  }}
                />
                <Typography variant="body1">Example &quot;Standard NDA&quot;</Typography>
              </div>
            </div>

            <div className="row pt-5">
              <div className="col">
                <InputLabel id="comment-label" className={classes.labelText}>
                  Comment
                </InputLabel>
                <TextField
                  variant="outlined"
                  error={watchAll.comment?.length >= COMMENT_LENGTH_LIMIT}
                  fullWidth
                  {...register('comment')}
                  inputProps={{
                    'aria-labelledby': 'comment-area-label',
                  }}
                />
                <Typography variant="body1">{`Limit of ${watchAll.comment?.length}/${COMMENT_LENGTH_LIMIT} characters`}</Typography>
              </div>
            </div>

            {file ? null : (
              <div className="row pt-5">
                <div className="col">
                  {edit ? (
                    <InputLabel
                      id="contractType-label"
                      style={{ color: 'gray' }}
                      className={classes.labelText}
                    >
                      Contract Type
                    </InputLabel>
                  ) : (
                    <InputLabel id="contractType-label" className={classes.labelText}>
                      Contract Type
                    </InputLabel>
                  )}
                  <Select
                    variant="outlined"
                    fullWidth
                    {...register('contractType')}
                    value={getValues('contractType') || ''}
                    inputProps={{
                      'aria-labelledby': 'contractType-label',
                    }}
                    displayEmpty
                    disabled={edit}
                    renderValue={(selected) => {
                      if (!selected) {
                        return <em>Please Select</em>;
                      }
                      const displayName = contractTypes.filter(
                        ({ name }) => name === selected
                      );
                      if (displayName.length > 0) {
                        return displayName[0].label;
                      }
                      return selected;
                    }}
                  >
                    <MenuItem value="" disabled>
                      <em>Please Select</em>
                    </MenuItem>
                    {contractTypes.map((item) => (
                      <MenuItem key={item.name} value={item.name}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            )}

            <div className="row pt-5">
              <div className="col">
                <InputLabel id="groups-label" className={classes.labelText}>
                  Playbook Groups
                </InputLabel>
                <Select
                  variant="outlined"
                  fullWidth
                  multiple
                  {...register('groups')}
                  value={getValues('groups') || ''}
                  inputProps={{
                    'aria-labelledby': 'groups-label',
                  }}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>Please Select</em>;
                    }
                    return selected.map((groupId) => groupLookup[groupId]).join(', ');
                  }}
                >
                  <MenuItem value="" disabled>
                    <em>Please Select</em>
                  </MenuItem>
                  {groups.map((item) => (
                    <MenuItem key={item._id} value={item._id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>

            <div className="row pt-5">
              <div className="col">
                <InputLabel id="stage-label" className={classes.labelText}>
                  Playbook Status
                </InputLabel>
                <Select
                  variant="outlined"
                  fullWidth
                  {...register('stage')}
                  value={getValues('stage') || ''}
                  inputProps={{
                    'aria-labelledby': 'stage-label',
                  }}
                  displayEmpty
                  disabled={showNewPlaybookBuilder}
                >
                  <MenuItem value="" disabled>
                    <em>Please Select</em>
                  </MenuItem>
                  {modelStageOptions.map((item) => (
                    <MenuItem key={item.key} value={item.key}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
                {getValues('stage') === 'backup' ? (
                  <Typography className={classes.warningText}>
                    WARNING: Setting this status will hide the model from view in the
                    dashboard.
                  </Typography>
                ) : null}
              </div>
            </div>

            <div className="row pt-5">
              <div className="col">
                <InputLabel
                  id="bullseyeTrainingModels-inputLabel"
                  className={classes.labelText}
                >
                  Bullseye Playbooks (additional playbooks to draw from)
                </InputLabel>
                <Select
                  variant="outlined"
                  fullWidth
                  multiple
                  {...register('bullseyeTrainingModels')}
                  value={getValues('bullseyeTrainingModels') || []}
                  inputProps={{
                    'aria-labelledby': 'bullseyeTrainingModels-label',
                  }}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>Please Select</em>;
                    }
                    return selected.map((modelId) => modelLookup[modelId]).join(', ');
                  }}
                >
                  <MenuItem value="" disabled>
                    <em>Please Select</em>
                  </MenuItem>
                  {trainingModels.map(
                    (item) =>
                      (!model || (model && item._id !== model._id)) && (
                        <MenuItem key={item._id} value={item._id}>
                          {item.name}
                        </MenuItem>
                      )
                  )}
                </Select>
              </div>
            </div>

            {getFilteredQuestionnaires().length ? (
              <div className="row pt-5">
                <div className="col">
                  <InputLabel
                    id="default-questionnaire-label"
                    className={classes.labelText}
                  >
                    Default Questionnaire (Optional)
                  </InputLabel>
                  <Select
                    variant="outlined"
                    fullWidth
                    {...register('defaultQuestionnaire')}
                    value={getValues('defaultQuestionnaire') || ''}
                    inputProps={{
                      'aria-labelledby': 'default-questionnaire-label',
                    }}
                    displayEmpty
                    renderValue={(selected) => {
                      if (!selected) {
                        return <em>Please Select</em>;
                      }
                      const target = questionnaires.find(({ _id }) => _id === selected);
                      return `[${target.model}] ${target.name} -- ${target.size} question${target.size === 1 ? '' : 's'}`;
                    }}
                  >
                    <MenuItem value="" disabled>
                      <em>Please Select</em>
                    </MenuItem>
                    {getFilteredQuestionnaires().map((item) => (
                      <MenuItem key={item._id} value={item._id}>
                        [{item.model}] {item.name} -- {item.size} question
                        {item.size === 1 ? '' : 's'}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            ) : null}

            <div className="row pt-5">
              <div className="col">
                <FormControlLabel
                  control={
                    <Switch
                      checked={getValues('isDeleteProtected')}
                      onChange={() =>
                        setValue('isDeleteProtected', !getValues('isDeleteProtected'))
                      }
                      name="isDeleteProtected"
                      color="primary"
                    />
                  }
                  label={getValues('isDeleteProtected') ? 'Hide Delete' : 'Show Delete'}
                />
              </div>
              <div className="col">
                <FormControlLabel
                  control={
                    <Switch
                      checked={getValues('enableRiskRouting')}
                      onChange={() =>
                        setValue('enableRiskRouting', !getValues('enableRiskRouting'))
                      }
                      name="enableRiskRouting"
                      color="primary"
                    />
                  }
                  label="Risk Routing"
                />
              </div>
              <div className="col">
                <FormControlLabel
                  control={
                    <Switch
                      checked={getValues('enableBullseyeByEmail')}
                      onChange={() =>
                        setValue(
                          'enableBullseyeByEmail',
                          !getValues('enableBullseyeByEmail')
                        )
                      }
                      name="enableBullseyeByEmail"
                      color="primary"
                    />
                  }
                  label="Bullseye by Email"
                />
              </div>
            </div>

            <div className="row pt-3">
              <div className="col">
                {!file && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={getValues('generateAddendum')}
                        onChange={() =>
                          setValue('generateAddendum', !getValues('generateAddendum'))
                        }
                        name="generateAddendum"
                        color="primary"
                      />
                    }
                    label={getValues('generateAddendum') ? 'Has Addendum' : 'No Addendum'}
                  />
                )}
              </div>
              <div className="col">
                <FormControlLabel
                  control={
                    <Switch
                      checked={getValues('emailOnUpload')}
                      onChange={() =>
                        setValue('emailOnUpload', !getValues('emailOnUpload'))
                      }
                      name="emailOnUpload"
                      color="primary"
                    />
                  }
                  label="Send email on upload"
                />
              </div>
              <div className="col">
                <FormControlLabel
                  control={
                    <Switch
                      checked={getValues('convertPdfToDocx')}
                      onChange={() =>
                        setValue('convertPdfToDocx', !getValues('convertPdfToDocx'))
                      }
                      name="convertPdfToDocx"
                      color="primary"
                    />
                  }
                  label="Convert PDF to DOCX"
                />
              </div>
            </div>

            {getValues('generateAddendum') && (
              <div className="row pt-3">
                <div className="col">
                  <InputLabel id="addendum-template-label" className={classes.labelText}>
                    Addendum Template
                  </InputLabel>
                  <TextField
                    variant="outlined"
                    defaultValue={getValues('addendumTemplate')}
                    onChange={(e) => setValue('addendumTemplate', e.target.value)}
                    fullWidth
                    inputProps={{
                      'aria-labelledby': 'addendum-template-label',
                    }}
                  />
                </div>
              </div>
            )}
            <div className="row pt-3">
              <div className="col">
                <FormControlLabel
                  control={
                    <Switch
                      checked={getValues('enableNewFsdMethod')}
                      onChange={() =>
                        setValue('enableNewFsdMethod', !getValues('enableNewFsdMethod'))
                      }
                      name="enableNewFsdMethod"
                      color="primary"
                    />
                  }
                  label="Enable New FSD Method"
                />
              </div>
            </div>
            <div className="row pt-3">
              <div className="col">
                <InputLabel id="nsmodel-template-label" className={classes.labelText}>
                  Nano Slot Model
                </InputLabel>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={
                    typeof getValues('nano_slot_model') === 'string'
                      ? getValues('nano_slot_model')
                      : JSON.stringify(getValues('nano_slot_model'))
                  }
                  onChange={(e) => setValue('nano_slot_model', e.target.value)}
                  multiline
                  rows={10}
                  inputProps={{
                    'aria-labelledby': 'nsmodel-template-label',
                  }}
                />
              </div>
            </div>

            <div className="row pt-5 pb-4">
              <div className="col">
                <Button variant="outlined" fullWidth onClick={handleCancel}>
                  Cancel
                </Button>
              </div>
              <div className="col" />
              <div className="col-auto">
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                  disabled={!formValid}
                >
                  {saveBtnText}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Dialog>
  );
}

CreateEditModel.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  model: PropTypes.object,
  trainingModels: PropTypes.array,
  setModel: PropTypes.any,
  groups: PropTypes.arrayOf(PropTypes.object).isRequired,
  questionnaires: PropTypes.arrayOf(PropTypes.object).isRequired,
  classes: PropTypes.object.isRequired,
};

const combinedStyles = CombineStyles(ModalsStyles, PlaybookBuilderStyles);
export default withStyles(combinedStyles)(CreateEditModel);
