import * as yup from 'yup';
import { addDays } from './DateUtils';

import {
  AutocompleteObj,
  CalendarObj,
  CheckboxObj,
  FileObj,
  RadioObj,
  TextObj,
} from '../pages/POA/ConfigClasses/classes';

export const hydrateInput = ({ type, ...rest }, dependencyMap, priorDependsOnStore) => {
  switch (type) {
    case 'calendar':
      return new CalendarObj(rest, dependencyMap).toJSON();
    case 'text':
      return new TextObj(rest, dependencyMap, priorDependsOnStore).toJSON();
    case 'autocomplete':
      return new AutocompleteObj(rest, dependencyMap).toJSON();
    case 'radio':
      return new RadioObj(rest, dependencyMap).toJSON();
    case 'checkbox':
      return new CheckboxObj(rest, dependencyMap).toJSON();
    case 'file':
      return new FileObj(rest, dependencyMap, priorDependsOnStore).toJSON();
    default:
      throw Error(`Unknown type ${type} for input object ${rest}`);
  }
};

export const hydrateConfig = (config, dependencyMap, priorDependsOnStore) =>
  config.map(({ summary, title, description, sections }) => ({
    summary,
    title,
    description,
    sections: sections.map(
      ({ title: sectionTitle, description: sectionDescription, inputs }) => ({
        title: sectionTitle,
        description: sectionDescription,
        inputs: inputs.map((inputObj) =>
          hydrateInput(inputObj, dependencyMap, priorDependsOnStore)
        ),
      })
    ),
  }));

export const getConfigDependencyMap = (config) =>
  config.reduce((acc, { sections }) => {
    sections.forEach(({ inputs }) => {
      inputs.forEach(({ id, dependencyMap }) => {
        if (dependencyMap) acc[id] = dependencyMap;
      });
    });
    return acc;
  }, {});

export const generateCurrentVisibilityMap = (values, key, visibilityMap) => {
  /*
    Takes in a full visibility mapping for all possible results and returns a consolidated map with only the applicable visibility mappings
     */
  const selectedMaps = values[key]
    .map((selection) => visibilityMap[selection])
    .reduce((acc, arr) => {
      (arr || []).forEach((visibleBoolMap) => {
        // e.g. {elementId: false}
        Object.entries(visibleBoolMap).forEach(([elementId, visibleBool]) => {
          if (elementId in acc) {
            if (!acc[elementId]) acc[elementId] = visibleBool;
          } else {
            acc[elementId] = visibleBool;
          }
        });
      });
      return acc;
    }, {});
  return selectedMaps;
};

export const genValidationSchema = (config) => {
  const yupConf = config.reduce((acc, { sections = [] }) => {
    const poaYupGenericErrorMsg = 'Required field.';
    sections.forEach(({ inputs }) => {
      inputs.forEach(({ id, type, required, validation }) => {
        let yupObj;

        // Special case for "Other" on [scopeSubType], [scopeWording] needs to be Text input
        if (id === 'scopeWording') {
          const runtimeCheck = (val) => {
            if (Array.isArray(val)) {
              return yup
                .array(poaYupGenericErrorMsg)
                .of(yup.string(poaYupGenericErrorMsg))
                .min(1, poaYupGenericErrorMsg);
            }
            return yup.string(poaYupGenericErrorMsg).required(poaYupGenericErrorMsg);
          };
          yupObj = yup.lazy(runtimeCheck);
        } else {
          switch (type) {
            case 'calendar':
              yupObj = yup.date(poaYupGenericErrorMsg);
              break;
            case 'text':
              if (validation) {
                switch (validation) {
                  case 'email':
                    yupObj = yup
                      .string(poaYupGenericErrorMsg)
                      .email('Must be valid email.');
                    break;
                  default:
                    break;
                }
              } else {
                yupObj = yup.string(poaYupGenericErrorMsg);
              }
              break;
            case 'autocomplete':
              yupObj = yup
                .array(poaYupGenericErrorMsg)
                .of(yup.string(poaYupGenericErrorMsg))
                .min(1, poaYupGenericErrorMsg);
              break;
            case 'radio':
              yupObj = yup
                .string(poaYupGenericErrorMsg)
                .nullable(false, poaYupGenericErrorMsg);
              break;
            case 'checkbox':
              yupObj = yup
                .boolean(poaYupGenericErrorMsg)
                .oneOf([true], poaYupGenericErrorMsg);
              break;
            case 'file':
              yupObj = yup.mixed(poaYupGenericErrorMsg);
              break;
            default:
              throw Error(`Unrecognized type ${type}`);
          }
          if (required) {
            yupObj = yupObj.required(poaYupGenericErrorMsg);
          }
        }
        acc[id] = yupObj;
      });
    });
    return acc;
  }, {});
  return yupConf;
};

function fetchVirtualFields(poaConfig) {
  const virtualFieldMap = {};
  poaConfig.forEach((group) => {
    group?.sections?.forEach((section) => {
      section?.inputs?.forEach((input) => {
        if (input.virtual) {
          virtualFieldMap[input.id] = input;
        }
      });
    });
  });
  return virtualFieldMap;
}

function readVirtualTemplateValue(
  id,
  type,
  template,
  poaRecord,
  fieldSub = {},
  initialFieldValue = null,
  dereferenceFieldValue = null
) {
  const getFieldId = (f) => fieldSub[f] || f;
  let { fieldId } = template;
  const { value, valueMap, valueMapDefault } = template;
  fieldId = getFieldId(fieldId);
  initialFieldValue = initialFieldValue || poaRecord[fieldId];
  dereferenceFieldValue = poaRecord[fieldId];
  if (typeof value === 'object' && getFieldId(value.fieldId) in poaRecord) {
    dereferenceFieldValue = readVirtualTemplateValue(
      id,
      type,
      value,
      poaRecord,
      fieldSub,
      initialFieldValue,
      dereferenceFieldValue
    );
  } else if (typeof value === 'number') {
    dereferenceFieldValue = value;
  } else {
    if (valueMap) {
      if (Array.isArray(dereferenceFieldValue)) {
        dereferenceFieldValue = dereferenceFieldValue.find((d) => valueMap[d]);
      }
      dereferenceFieldValue = valueMap[dereferenceFieldValue];
      if (dereferenceFieldValue == null) {
        if (valueMapDefault == undefined) {
          throw new Error(
            `Virtual field "${id}" did not resolve to` +
              ` a value in the value map. Set "valueMapDefault" to fix.`
          );
        }
        dereferenceFieldValue = valueMapDefault;
      }
    } else if (dereferenceFieldValue == null) {
      throw new Error(`Virtual field "${id}" did not resolve to a value`);
    }
  }

  // This has the possibility of working for other types as well
  switch (type) {
    case 'calendar':
      if (typeof dereferenceFieldValue !== 'number') {
        // Maybe its a date
        const possibleDate = new Date(dereferenceFieldValue);
        if (possibleDate == 'Invalid Date') {
          throw new Error(
            `Virtual field "${id}" must resolve to a specific number of days` +
              ` or a Date for "calendar" type but instead resolved to type` +
              ` "${typeof dereferenceFieldValue}" with value "${dereferenceFieldValue}".`
          );
        }
        dereferenceFieldValue = possibleDate;
      }
      if (dereferenceFieldValue instanceof Date) {
        return dereferenceFieldValue;
      }
      return addDays(initialFieldValue, dereferenceFieldValue);
    default:
      throw new Error(
        `Virtual field "${id}" has unsupported type "${type}" but this can be extended.`
      );
  }
}

export function fetchVirtualValue({ id, type, virtual }, poaRecord, fieldSub = {}) {
  const resolvedValue = readVirtualTemplateValue(id, type, virtual, poaRecord, fieldSub);
  return resolvedValue;
}

export function updateReactiveDependencies({
  id,
  newValue,
  reactiveDependencyMap,
  formik,
  fieldSub = {},
}) {
  const deps = reactiveDependencyMap[id];
  (deps || []).forEach(
    ({ defaultValue: { value: daysDiff, useVirtual }, type, id: fieldId, virtual }) => {
      switch (type) {
        case 'calendar':
          if (useVirtual) {
            const newVirtVal = fetchVirtualValue(
              { id, type, virtual },
              { ...formik.values, [id]: newValue },
              fieldSub
            );
            formik.setFieldValue(fieldId, newVirtVal);
          } else if (daysDiff && newValue instanceof Date) {
            formik.setFieldValue(fieldId, addDays(newValue, daysDiff));
          }
          break;
        default:
          throw new Error(
            `Unsupported input type "${type}" for the field dependency from "${id}"`
          );
      }
    }
  );
}

export function calculateVirtualFields(configTemplate, poaRecord) {
  const virtualFields = fetchVirtualFields(configTemplate);
  // Overwrite data with virtual values
  const hasVirtual = Object.keys(poaRecord).some((fieldKey) => fieldKey in poaRecord);
  if (!hasVirtual) {
    return poaRecord;
  }
  const newPoaRecord = { ...poaRecord };
  for (const vField in virtualFields) {
    newPoaRecord[vField] = fetchVirtualValue(virtualFields[vField], poaRecord);
  }
  return newPoaRecord;
}

export function backfillMetrix(poaRecord) {
  const metrixField = 'metrixTargetMet';
  const newPoaRecord = { ...poaRecord, ...poaRecord.tracking };
  const { sentForSignatureDate, poaEffectiveDate } = newPoaRecord;
  if (sentForSignatureDate && poaEffectiveDate) {
    newPoaRecord[metrixField] =
      new Date(sentForSignatureDate) <= new Date(poaEffectiveDate);
  }
  return newPoaRecord;
}

export function getInputsFromConfig(config) {
  return config.flatMap((group) =>
    group?.sections?.flatMap((section) => section?.inputs)
  );
}

export function getInputsFromConfigAsMap(config) {
  const inputs = getInputsFromConfig(config);
  return inputs.reduce((acc, current) => {
    acc[current.id] = current;
    return acc;
  }, {});
}

export function isFile(obj) {
  return 'File' in window && obj[0]?.file instanceof File;
}

export function buildInputs(prettyKeysObj) {
  return Object.entries(prettyKeysObj).map(([key, val]) => {
    const retVal = { key };
    if (typeof val === 'string') {
      return { ...retVal, title: val };
    }
    return { ...retVal, ...val };
  });
}
