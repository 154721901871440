import { addDays } from '../../../utils/DateUtils';

/* eslint-disable max-classes-per-file */
const truthyValueOrDefault = (value, defaultValue) => {
  if (typeof value === 'boolean' && value === false) return value;
  if (typeof value === 'string' && value.length > 0) return value;
  if (value) return value;
  return defaultValue;
};

export class AutocompleteObj {
  constructor(
    {
      id,
      title,
      options,
      dependsOn,
      multiple,
      multiline,
      placeholder,
      sort,
      sharedOnly,
      required,
      errorText,
      visible,
      showSelectAll,
    },
    dependencyMap,
    priorDependsOnStore
  ) {
    this.id = id;
    this.title = title;
    this.options = options || [];
    this.defaultValue = [];
    this.dependsOn = dependsOn || null;
    this.multiline = truthyValueOrDefault(multiline, false);
    this.multiple = truthyValueOrDefault(multiple, true);
    this.placeholder = placeholder || 'Select an option...';
    this.sharedOnly = truthyValueOrDefault(sharedOnly, false);
    this.sort = truthyValueOrDefault(sort, true);
    this.required = truthyValueOrDefault(required, true);
    this.errorText = errorText || '';
    this.visible = truthyValueOrDefault(visible, true);
    this.dependencyMap = dependencyMap || {};
    this.showSelectAll = truthyValueOrDefault(showSelectAll, true);
    this.type = 'autocomplete';

    this.priorDependsOnStore = priorDependsOnStore || {};
    this.priorDependsOnStore[this.id] = null;
  }

  toJSON() {
    return {
      id: this.id,
      title: this.title,
      options: this.options,
      defaultValue: this.defaultValue,
      dependsOn: this.dependsOn,
      multiline: this.multiline,
      multiple: this.multiple,
      placeholder: this.placeholder,
      sharedOnly: this.sharedOnly,
      sort: this.sort,
      required: this.required,
      errorText: this.errorText,
      visible: this.visible,
      dependencyMap: this.dependencyMap,
      showSelectAll: this.showSelectAll,
      priorDependsOnStore: this.priorDependsOnStore,
      type: this.type,
    };
  }
}

export class RadioObj {
  constructor(
    { id, title, options, dependsOn, defaultValue, required, errorText, visible },
    dependencyMap,
    priorDependsOnStore
  ) {
    this.id = id;
    this.title = title;
    this.options = options || [];
    this.dependsOn = dependsOn || null;
    this.defaultValue = truthyValueOrDefault(defaultValue, '');
    this.required = truthyValueOrDefault(required, true);
    this.errorText = errorText || '';
    this.visible = truthyValueOrDefault(visible, true);
    this.dependencyMap = dependencyMap || {};
    this.type = 'radio';

    this.priorDependsOnStore = priorDependsOnStore || {};
    this.priorDependsOnStore[this.id] = null;
  }

  toJSON() {
    return {
      id: this.id,
      title: this.title,
      options: this.options,
      defaultValue: this.defaultValue,
      dependsOn: this.dependsOn,
      required: this.required,
      errorText: this.errorText,
      visible: this.visible,
      dependencyMap: this.dependencyMap,
      priorDependsOnStore: this.priorDependsOnStore,
      type: this.type,
    };
  }
}

export class CalendarObj {
  constructor(
    {
      id,
      title,
      defaultValue,
      disabled,
      backward,
      forward,
      dependsOn,
      required,
      errorText,
      visible,
    },
    dependencyMap,
    priorDependsOnStore
  ) {
    this.id = id;
    this.title = title;

    this.disabled = disabled;
    this.backward = (backward || 60) * -1;
    this.forward = forward || 200;
    this.dependsOn = dependsOn || null;
    this.required = truthyValueOrDefault(required, true);
    this.errorText = errorText || '';
    this.visible = truthyValueOrDefault(visible, true);
    this.dependencyMap = dependencyMap || {};
    this.type = 'calendar';

    this.priorDependsOnStore = priorDependsOnStore || {};
    this.priorDependsOnStore[this.id] = null;

    // Set default date based on options in configuration
    if (defaultValue) {
      if (typeof defaultValue === 'number') {
        this.defaultValue = addDays(new Date(), defaultValue);
      } else if (defaultValue instanceof Date) {
        this.defaultValue = defaultValue;
      } else if (typeof defaultValue === 'object') {
        // useVirtual, fieldDependency exists here as well, but not relevant until formik is initialized
        const { value, useVirtual, fieldDependencies } = defaultValue;
        if (!value && !useVirtual && !fieldDependencies) {
          console.warn(
            `Default value for field ${this.id} is type object but missing one or more of required keys` +
              ` [ fieldDependencies | value | useVirtual ]`
          );
          this.defaultValue = new Date();
        } else if (value) {
          this.defaultValue = addDays(new Date(), value);
        }
      } else {
        throw new Error(
          `Invalid type of defaultValue, must be 'Number', 'Date', or 'object' but was type ${typeof defaultValue}`
        );
      }
    } else {
      this.defaultValue = new Date();
    }
  }

  toJSON() {
    return {
      id: this.id,
      title: this.title,
      defaultValue: this.defaultValue,
      disabled: this.disabled,
      backward: this.backward,
      forward: this.forward,
      dependsOn: this.dependsOn,
      required: this.required,
      errorText: this.errorText,
      visible: this.visible,
      dependencyMap: this.dependencyMap,
      priorDependsOnStore: this.priorDependsOnStore,
      type: this.type,
    };
  }
}

export class TextObj {
  constructor(
    {
      id,
      title,
      defaultValue,
      multiline,
      rows,
      maxRows,
      dependsOn,
      required,
      errorText,
      visible,
      validation,
      style,
    },
    dependencyMap,
    priorDependsOnStore
  ) {
    this.id = id;
    this.title = title;
    this.defaultValue = truthyValueOrDefault(defaultValue, '');
    this.multiline = truthyValueOrDefault(multiline, false);
    this.rows = rows || 5;
    this.maxRows = maxRows || 5;
    this.dependsOn = dependsOn || null;
    this.required = truthyValueOrDefault(required, true);
    this.errorText = errorText || '';
    this.visible = truthyValueOrDefault(visible, true);
    this.dependencyMap = dependencyMap || {};
    this.type = 'text';
    this.validation = validation;
    this.style = style || {};

    this.priorDependsOnStore = priorDependsOnStore || {};
    this.priorDependsOnStore[this.id] = null;
  }

  toJSON() {
    return {
      id: this.id,
      title: this.title,
      defaultValue: this.defaultValue,
      multiline: this.multiline,
      rows: this.rows,
      maxRows: this.maxRows,
      dependsOn: this.dependsOn,
      required: this.required,
      errorText: this.errorText,
      visible: this.visible,
      dependencyMap: this.dependencyMap,
      priorDependsOnStore: this.priorDependsOnStore,
      type: this.type,
      validation: this.validation,
      style: this.style,
    };
  }
}

export class FileObj {
  constructor(
    { id, title, showTitle, dependsOn, required, errorText, visible },
    dependencyMap,
    priorDependsOnStore
  ) {
    this.id = id;
    this.title = title;
    this.showTitle = truthyValueOrDefault(showTitle, true);
    this.defaultValue = [];
    this.dependsOn = dependsOn || null;
    this.required = truthyValueOrDefault(required, true);
    this.errorText = errorText || '';
    this.visible = truthyValueOrDefault(visible, true);
    this.dependencyMap = dependencyMap || {};
    this.type = 'file';

    this.priorDependsOnStore = priorDependsOnStore || {};
    this.priorDependsOnStore[this.id] = null;
  }

  toJSON() {
    return {
      id: this.id,
      title: this.title,
      showTitle: this.showTitle,
      defaultValue: this.defaultValue,
      dependsOn: this.dependsOn,
      required: this.required,
      errorText: this.errorText,
      visible: this.visible,
      dependencyMap: this.dependencyMap,
      priorDependsOnStore: this.priorDependsOnStore,
      type: this.type,
    };
  }
}

export class CheckboxObj {
  constructor(
    { id, title, dependsOn, defaultValue, required, errorText, visible },
    dependencyMap,
    priorDependsOnStore
  ) {
    this.id = id;
    this.title = title;
    this.dependsOn = dependsOn || null;
    this.defaultValue = truthyValueOrDefault(defaultValue, false);
    this.required = truthyValueOrDefault(required, true);
    this.errorText = errorText || '';
    this.visible = truthyValueOrDefault(visible, true);
    this.dependencyMap = dependencyMap || {};
    this.type = 'checkbox';

    this.priorDependsOnStore = priorDependsOnStore || {};
    this.priorDependsOnStore[this.id] = null;
  }

  toJSON() {
    return {
      id: this.id,
      title: this.title,
      defaultValue: this.defaultValue,
      dependsOn: this.dependsOn,
      required: this.required,
      errorText: this.errorText,
      visible: this.visible,
      dependencyMap: this.dependencyMap,
      priorDependsOnStore: this.priorDependsOnStore,
      type: this.type,
    };
  }
}
